.pre-log-in-nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 100px;
    left: 0px;
    border-top: 1px solid lightgrey;

    @media screen and (max-width: 768px) { 
        max-width: 100vw;
        top: 65px;
    }
}

.nav {
    width: 100%;
    background-color: white;

    @media (max-width: 1024px) {

        .nav-content {
            justify-content: flex-end;
            background-color: #fff;
        }

        .left-nav {
            display: none;
        }

        .right-nav {
            display: none;
        }

        .hamburger {
            display: block;
        }
    }
}

.hamburger {
    display: none;
    margin: auto 0;
    padding: 20px;
}

.nav-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-nav {
    width: 55%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.right-nav {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.nav-item {
    width: fit-content;
    height: fit-content;
    margin: auto;
    font-weight: 700;

    &.icon {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 10px auto;
        cursor: pointer;
    }

    &.logo {
        background-color: white;
        margin: auto;
    }

    .progress-animation {
        position: absolute;
    }
}

.nav-search {
    background-color: #F4F4F4;
    width: 150px;
    height: 29px;
    border: none;
}

.nav-dropdown {
    display: flex;
    flex-direction: column;
    padding: 20px;

    &.hide {
        display: none;
    }

    &__header {
        margin: 5px 0 5px 10px;
        display: flex;
        align-items: center;
    }

    &__copy > * {
        margin: 5px 10px;
    }

    &__topics-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__topic {
        border: 1px solid #000000;
        cursor: pointer;
        flex-basis: 15%;
        padding: 10px;
        margin: 10px;
        text-align: center;
        flex: 1;

        &.active {
            background: #1373E6;
            color: #fff;
        }

        @media screen and (max-width: 768px) {
            flex-grow: 0;
        }
    }
}

