.hero-banner {
    width: 100%;
    height: 530px;
    background-image: url(../../images/herobanner.png);
    background-repeat: no-repeat;
    background-position-y: 45%;
    background-size: cover;
    color: white;
    font-family: Roboto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 110px;

    @media (max-width: 768px) {
        background-position: center;
        height: 600px;
        padding-top: 65px;
    }    
}

h1 {
    font-size: 48px;
    margin: 20px 0 8px 0;

    @media (max-width: 768px) {
        font-size: 40px;
    }    
}

.hero-banner-copy {
    font-size: 20px;
    width: 75%;
    margin: 8px 0;
}

.hero-banner-content {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 4em;
    margin-bottom: 15.5em;

    @media (max-width: 768px) {
        width: 100%;
        padding-left: 2em;
    }
}

.hero-banner-btn {
    margin: 40px 0;
    cursor: pointer;
}
