.grid {
    box-sizing: border-box;
    // height: 100vh;
    padding: 1.5em;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 1.5em;
    margin-top: 108px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(12, auto);
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;
    }
}
