.progress-animation {
    cursor: pointer;
    display: inline-block;
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    border-radius: 50%;
    background-color: #fa0e00;
    border: 2px solid rgba(20, 90, 20, 0.2);
    box-shadow: 0 0 0 10px rgba(20, 115, 230, 0.3);
    animation: progress-pulse 2s linear -4s infinite;

}

@keyframes progress-pulse {
    0% {
        box-shadow: 0 0 0 0 lighten($color: #fa0e00, $amount: 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px lighten($color: #fa0e0000, $amount: 1);
    }
    100% {
        box-shadow: 0 0 0 0 lighten($color: #fa0e0000, $amount: 0.1);
    }
}

