.article-list-item {
    display: flex;
    flex-direction: row;

    &__image {
        width: 100px;
        height: 100px;
        object-position: center;
        object-fit: cover;
        padding-right: 15px;
    }

    &__copy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title, &__description {
        margin: 0;
    }

    &__link {
        color: #505051;
        font-weight: bold;
        cursor: pointer;
    }
}