.button {
    padding: 15px 30px;
    min-width: 160px;
    border-radius: 30px;
    border: 2px solid #1373E6;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;

    &.primary {
        background-color: #1373E6;
        color: #ffffff;
    }

    &.secondary {
        background-color: #ffffff;
        color: #1373E6;
    }

    &.inactive {
        background-color: #ffffff;
        color: #505051;
        border: 2px solid #505051;
    }
}