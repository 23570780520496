.chloe-8-summary {

    > h2 {
        font-size: 26px;
    }

    a {
        text-decoration: none;
        color: white;
    }
    
    @media(max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        button {
            margin: 10px 0;

        }
    }

    &-cta {
        margin-left: 10px;
        margin-right: 10px;

        @media screen and (max-width:768px) {
            width: 70%;
        }
    }
}

.chloe-8-score {

    height: fit-content;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > h2 {
        text-align: center;
    }

    > .score {
        margin: auto;
        font-size: 36px;
        font-weight: 700;
    }
}

.chloe-8-icons {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0;

    @media(max-width: 768px) {
        justify-content: center;
        flex-wrap: wrap;
        margin: 10px 0;
        padding-left: 24px;
    }

    > .chloe-8-icon {
        width: 50px;
        height: 50px;
        background-position: center;
        margin: 0 5px;
    }
}

.chloe-8-results {

    margin-bottom: 10px;

    > .chloe-8-result {
        margin: 20px 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;


        @media(max-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            text-align: center;
        }

        .result-icon {
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-position: center;

            @media(max-width: 768px) {
                margin: auto;
            }
            
        }

        .result-detail {
            width: 100%;
            margin: 0 40px 0 10px;

            @media(max-width: 768px) {
                margin: auto;
            }
        }

        .result-answer {
            height: fit-content;
            text-align: center;
            border: 1px solid #E9E9E9;
            font-size: 26px;
            padding: 10px;
            margin: auto;

            @media(max-width: 768px) {
                
            }
        }
    }
}

.one-8 {
    background-image: url("./assets/page-8-icons/icon-1.svg");
}

.two-8 {
    background-image: url("./assets/page-8-icons/icon-2.svg");
}

.three-8 {
    background-image: url("./assets/page-8-icons/icon-3.svg");
}

.four-8 {
    background-image: url("./assets/page-8-icons/icon-4.svg");
}

.five-8 {
    background-image: url("./assets/page-8-icons/icon-5.svg");
}

.six-8 {
    background-image: url("./assets/page-8-icons/icon-6.svg");
}

.seven-8 {
    background-image: url("./assets/page-8-icons/icon-7.svg");
}

.eight-8 {
    background-image: url("./assets/page-8-icons/icon-8.svg");
}

.nine-8 {
    background-image: url("./assets/page-8-icons/icon-9.svg");
}

.ten-8 {
    background-image: url("./assets/page-8-icons/icon-10.svg");
}