html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto';
  background-color: #E5E5E5;
}

#root {
  padding-top: 100px;
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    padding-top: 65px;
  }
}