.lightbox {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    animation: fadeIn ease 0.4s;
    background: #00000066;
    z-index: 13;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;

    &.hidden {
        display: none;
    }

    &.tour {
        overflow-y: hidden;
    }

    .tt-container {
        animation: fadeIn ease 1s;
    }

    .lightbox-tooltip {
        background: #fff;
        box-shadow: 2px 10px 10px #55555555;
        min-height: 140px;
        width: 300px;
        border-left-style: solid;
        border-left-width: 5px;
        box-sizing: border-box;
        padding: 15px;

        &.large {
            min-height: 140px;
            width: 50%;
            min-height: 40%;
            border-left:  none;
            border-radius: 15px;
            padding: 1.5em;

            @media screen and (max-width: 768px) {
                width: 90%;
                margin-top: 15%;
            }
        }

        &.bump {
            margin-bottom: 50px;
        }

        &.large.tour-tt {
            margin: 0px auto;

            p {
                font-size: 14px;
                padding: 0px 50px;
                line-height: 1.2rem;
                text-align: center;
            }

            button {
                background: #1473E6;
                border-radius: 20px;
                color: #fff;
                font-weight: bold;
                padding: 10px 20px;
                float: none;
                margin: 0px auto;
                display: block;
                margin-top: 20px;
            }
        }

        &.hotspot {
            height: auto;
            width: 320px;
            border-left:  none;
            border-radius: 0px;
            padding: 20px;
            animation: progress-pulse 2s linear -4s infinite;
            box-shadow: 0 0 0 10px #1473E6CC;
            background-color: #000000;
            color: #ffffff;

            h2, p {
                text-align: left;
                font-size: 14px;
                line-height: 1.3;
            }

            h2 {
                font-size: 18px;
                margin-top: 0px;
                display: flex;

                &::before {
                    content: url("../../images/AdobeHotspotLogo.svg");
                    margin-right: 10px;
                }
            }

            p {
                font-weight: normal;
            }

            .hotspot-resume {
                text-align: right;
                color: #ffffff;
                margin-top: 20px;
                margin-bottom: 0px;
                font-weight: 600;
            }

            .dismissal-bar {
                font-weight: normal;
                font-size: 24px;
            }
        }
    }
}

.positioned {
    position: absolute;
    left: var(--left);
    top: var(--top);
    display: flex;
    flex-direction: var(--flex);
    align-items: center;
}

.tour-dot-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    top: 35px;
    height: 0px;
}

.dismissal-bar {
    display: flex;
    justify-content: flex-end;
    line-height: 0.5;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.tour-dot {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    background: #fff;
    margin: 0px 8px;
    cursor: pointer;
    box-sizing: content-box;
}

.tour-dot.active {
    box-sizing: border-box;
    height: 12px;
    width: 12px;
    padding: 2px;
    background: #1473E6 content-box;
    border: 1px solid #1473E6;
}

.lightbox-highlight {
    z-index: 25;
    
    &.true {
        position: relative;
    }
}

.caret.right {
    width: 0; 
    height: 0; 
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent; 
    border-right:15px solid  #1473E6; 
}

.caret.bottom {
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent; 
    border-bottom: 15px solid  #fff; 
}

.tour-tt {

    h2 {
        font-size: 19px;
        font-weight: normal;
        margin: 5px 0px 10px 0px;
    }

    .dismissal-bar {
        font-size: 20px;
    }

    button {
        background: none;
        border: none;
        color: #1473E6;
        padding: 0px;
        float: right;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    p {
        margin: 5px 0px 10px 0px;
        font-size: 13px;
        line-height: 1.15rem;
    }

    .skip-tt {
        font-weight: bold;
        float: right;
        cursor: pointer;
        text-align: right;
        margin: 0px;
    }
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}