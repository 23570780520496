.tech-drawer {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 12;

    @media screen and (max-width: 768px) { max-width: 100vw; }
}

.tech-drawer-bar {
    width: 100%;
    height: 3.125em;
    background-color: black;
    display: flex; 
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}

.tech-drawer-chevron {
    margin: auto 0;
    transform: rotate(180deg);


    &.true {
        transform: rotate(0deg);
    }
}

.tech-drawer-title {
    color: white;
    font-size: 1.5em;
    text-align: center;
    margin: auto 2em;
}

.tech-drawer-main.hide {

    & .tech-drawer-detail {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.tech-drawer-content-wrapper {
    height: 232px;
    transition: all .25s;
    transform: translateY(0px);
    will-change: transform;

    @media (max-width:1024px) {
        height: 65vh;

        &.hide {
            height: 0;
    
        }
    }
    
    &.hide {
        height: 0;

    }
}

.tech-drawer-main {
    transition: all .25s;
    transition-delay: 0.25;
    height: 232px;
    background-color: white;
    display: grid;
    grid-template-columns: 40% 30% 30%;
    grid-template-rows: 100%;
    align-content: center;

    @media (max-width: 1024px) {
        min-height: 65vh;
        grid-template-columns: 100%;
        grid-template-rows: 4fr 2fr 3fr;
        justify-content: center;
    }

    @media screen and (max-width: 768px) { 
        grid-template-rows: 2.5fr 3fr 3fr;;
    }
}

.tech-drawer-detail {
    transition-delay: 0.25;
    background-color: white;
    color: #505051;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 1em 1em 1em 6.188em;

    & .detail-header {
        font-size: 1.375em;
    }

    & .detail-body {

        max-width: 28em;

        & .detail-persona {
            color: #0C15F3;
            font-weight: 700;
            text-transform: capitalize;
        }
    }

    .detail-container {
        display: flex;
        flex-direction: row;
    }

    & .detail-how-to {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        & .how-to-label {
            font-weight: 700;
            font-size: 0.875em;
            padding-left: 0.688em;
            margin: 0;
        }

        @media screen and (max-width: 768px) { display: none; }
    }

    .detail-privacy {
        margin-left: 75px;
        font-weight: 700;
        font-size: 0.875em;
        text-decoration: none;
        color: #505051;
        display: flex;
        flex-direction: row;
        cursor: pointer;

        & p {
            padding-left: 0.688em;
            margin: 0;
            text-decoration: none;
            color: #505051;
        }

        @media screen and (max-width: 768px) { margin: 10px 0px; }
    }

    @media (max-width: 1024px) {
        margin: 0 auto;
        justify-content: space-evenly;
    }

    @media (max-width: 550px) {
        margin: 0;
        justify-content: space-evenly;

        & > * {
            padding: 5px 20px;
        }

        .detail-header {
            padding-top: 15px;
        }
    }
}

.tech-drawer-persona-swap {
    transition-delay: 0.25;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2em;

    @media (max-width: 1024px) {
        margin: 0 auto;
    }

    @media screen and (max-width: 768px) { 
        grid-row: 1;
    }
}

.persona-swap-label {
    font-weight: 700;
    color: #505051;
    margin-bottom: 1em;

    @media (max-width: 1024px) {
        margin: 0 auto 1em 0;
    }
}

.tech-drawer-contact {
    transition-delay: 0.25;
    background-color: #1473E6;
    color: #FAFAFA;
}

.contact-wrapper {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: auto 4em;

    & .contact-header {
        font-weight: 700;   
    }

    & .contact-link {
        border: 1px solid white;
        width: fit-content;
        padding: 1em 2em;
        border-radius: 26px;
        text-decoration: none;
        color: white;
        margin: 0 auto;
    }

    @media (max-width: 1024px) {
        margin: 0;
        padding: 0;
        justify-content: space-evenly;

        & .contact-header {
            margin: 0 auto;
            padding: 0;
            text-align: center;
        }

        & .contact-body {
            margin: 0 auto;
        }

        & .contact-link {
            margin: 0 auto;
            padding: 8px 32px;
        }
    }
    
}

.journey-select {
    width: 276px;
    height: 43px;
    color: #505051;

    option {
        color: unset;
    }
}


.begin-intro-confirm {
    text-align: center;
    width: 80%;

    &__container > .lightbox-tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    &__button {
        margin-left: 10px;
        margin-right: 10px;
    }
}
