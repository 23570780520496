.screen {
    width: 100%;
    height: calc(100% - 109px);
    position: relative;
    bottom: 0;
    overflow: scroll;
}

.screen-side-nav {
    height: 100%;
    overflow: scroll;

    @media (max-width: 768px) {
        width: 100%;
    }
}