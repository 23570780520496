.list-container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-sizing: border-box;
    display: inline-block;

    .list-item-container {
        padding: 15px 0;

        &:not(:last-of-type) {
            border-bottom: 1px solid #CCCCCC;
        }
    }
}