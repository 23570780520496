.grid-tile {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 1.5em;
}


