.homepage {

    #form {
        width: min-content;
        margin: 450px 0px 20px 0px;

        @media screen and (max-width: 768px) {
            width: unset;
            margin: 340% 0 60px;
        }
    }
    
    .grid {
        margin-top: 50px;
    
        .grid-tile {
            padding: 2.5em;
    
            h2 {
                margin-top: 0px;
            }
        }
    }
        
    .news-sub {
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }
    
        strong, span {
            padding: 0px 5px;

            @media screen and (max-width: 768px) { padding: 5px 0px; }
        }
    
        span {
            color: #1473E6;
            text-decoration: underline;
            margin-right: 10px;
        }

        .progress-animation {
            align-self: flex-end;
            margin-bottom: 10px;
        }
    }
    
    .list-container {
        width: 100%;
        padding: 0px;
    
        .article-list-item__image {
            object-fit: none
        }
    
        h4 {
            font-weight: normal;
            font-size: 19px;
            margin-bottom: 10px;
        }
    
        p {
            line-height: 1.2rem;
        }
    
        .article-list-item__link {
            margin-top: 5px;
        }
    
        .button {
            margin-top: 20px;
        }
    }
    
    .app-listitem {
        display: flex;
        align-items: center;
    
        h4 {
            margin: 0px 0px 10px 20px;
        }
    
        .app-logo {
            height: 80px;
        }
    
        .rating-star {
            margin: 0px 2.5px;
    
            &:first-of-type {
                margin-left: 20px;
            }
        }
    }
}