.nav-container {
    grid-column: 1 / 3;
    position: fixed;
    top: 100px;
    width: 100%;
    z-index: 11;

    & + * {
        margin-top: 108px;
    }

    @media screen and (max-width: 768px) {
        top: 65px;
    }
}

.header-nav {
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.header-nav-content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 54px;
    background-color: white;

    @media (max-width: 768px) {
        justify-content: space-between;
    }
}

.header-nav-logo {
    margin: auto 0;
    width: 90px;
    text-align: center;

    @media (max-width: 768px) {
        width: auto;
        padding-left: 20px;
    }
}

.header-nav-item {
    background-color: white;
    width: 57px;
    height: 29px;
    margin: auto;
}

.header-nav-name {
    margin: auto 0;
    width: 100%;

    > .name {
        text-transform: uppercase;
        color: black;
        width: fit-content;
        padding: 3px 8px;
        letter-spacing: 1px;
        font-weight: bold;

        @media screen and (max-width: 480px) {
            font-size: 12px;
        }
    }

    @media (max-width: 768px) {
        width: auto;
    }
}

.header-nav-hamburger {
    margin: auto 0;
    padding-right: 103px;
    padding-left: 20px;

    @media (max-width: 768px) {
        padding-right: 20px;
    }
}

.header-nav-info {
    width: 100%;
    height: 54px;
    color: white;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .info-title {
        margin: auto;
    }
}

.side-nav {
    background-color: white;
    color: black;
    height: calc(100vh - 258px);
    width: 80px;
    position: fixed;
    display: inline-block;
    z-index: 11;

    @media (max-width: 768px) {
        display: none;
        height: calc(100vh - 223px);
        
    }

    @media (min-width: 769px) {
        & + * {
            margin-left: 80px;
        }
    }
}

.side-nav-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}

.side-nav-g1 {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
   height: 45%;
   position: relative;
}

.side-nav-g2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 20%;
}

.side-nav-item {
    background-color: white;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
}