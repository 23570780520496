.chloe-16 {
    &__content-container {
        display: flex;
        flex-direction: row;
    }

    &__content {
        width: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }


    &__main {
        background-color: #ffffff;
        border: 1px solid #E9E9E9;
        border-radius: 12px;
        padding: 40px;
        margin-bottom: 30px;
        width: 60%;
        text-align: center;

        @media screen and (max-width:768px) {
            width: 85%;
        }
        
        img {
            border: 1px solid #cccccc;
            border-radius: 6px;
            padding-top: 20px;
        }
    }

}