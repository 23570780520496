.footer {
    width: 100%;
    height: 150px;
    padding: 10px;
    box-sizing: border-box;
    background-color: #222222;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: #ffffff;
    text-align: center;

    @media screen and (max-width: 768px) { max-width: 100%; }
    
    &__links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
        }
    }
    
    &__links > &__copy {
        cursor: pointer;
        text-decoration: underline;
        margin: 0 20px;
    }
}