.persona-selector {
    width: 100%;
    height: 18em;
    background-color:rgba(255, 255, 255, 1);
    margin-top: -15.5em;
    padding-bottom: 30px;

    @media (max-width: 1024px) {
        height: fit-content;
    }

    a {
        text-decoration: none;
    }
}

.persona-selector-content {
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: start;
    grid-auto-flow: row;
    max-width: fit-content;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
        margin: 2em auto;
    }

    @media (max-width: 768px) {
        margin: 0px;
        width: 100%;
        max-width: 100%;
        grid-gap: 40px;
        box-sizing: border-box;
        padding: 0px 50px;
    }
}

.persona-selector-title {
    width: 100%;
    font-size: 18px;
    text-align: center;
    padding: 2.5em 0 1em 0;
    margin: 0;

    @media (max-width: 935px) {
        padding-top: 1.5em;
    }

    @media (max-width: 467px) {
        padding-top: 1em;
    }
}

.persona-selector-item {
    background-color: white;
    width: 193px;
    height: 10.625em;
    margin: 1.25em;
    box-sizing: content-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 100%;
    }

    &.pulse-animation {
        border-bottom-left-radius: 23px;
        border-bottom-right-radius: 23px;
        margin-bottom: 1em;
    }

    > .icon {
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 12px;

    }

    > .title {
        height: 3.125em;
        text-align: justify;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        display: flex;

        > .title-content {
            color: white;
            margin: auto;
            text-transform: uppercase;
            font-weight: 700;
        }
        
    }
}

