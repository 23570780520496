.adobe-header {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 200;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (max-width: 768px) { 
        max-width: 100vw; 
        height: 65px;
    }

    &__logo-container {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        color: #000000;
        font-weight: bold;
        width: 200p;
        text-decoration: none;
        height: 100px;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
            height: 65px; 
            padding-left: 10px;
        }
    }

    .adobe-logo {
        height: 24px;

        @media screen and (max-width: 768px) {
            height: 18px;
        }
    }
}