.faas-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 40%;
    max-width: 66%;
    min-width: 725px;
    color: #535353;
    background: #FAFAFA;
    border-radius: 8px;
    padding: 3em;

    .note {
        grid-column: span 2;
        text-align: center;
        font-size: 13px;
    }

    .row {
        display: flex;
        flex-direction: column;
    }

    label {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: bold;
    }

    input, select, textarea {
        padding: .8rem 1rem;
        width: 100%;
        box-sizing: border-box;
        border: 0 none;
        border-radius: .3rem;
        box-shadow: inset 0.067rem 0.067rem 0 0 #d5d5d5, inset -0.06231rem -0.06231rem 0 0 #d5d5d5;
        color: #535353;
    }

    select {
        background: #FAFAFA;
    }

    .democheckbox {
        box-shadow: none;
        width: auto;
        margin-bottom: 10px;
        margin-left: 0px;
    }

    .legal {
        grid-column: span 2;
        border-top: 1px solid #d5d5d5;
        font-size: 13px;

        a {
            font-weight: bold;
            text-decoration: none;
        }
    }

    .row.submit {
        grid-column: span 2;
        align-self: center;
    }

    input[type=submit] {
        background-color: #2680eb;
        color: #fff;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 16px;
        height: 32px;
        padding: 0 14px 1px;
        font-weight: 700;
        cursor: pointer;
        box-shadow: none;
        width: 15%;
        align-self: center;
    }

    #showhidetoggle {
        grid-column: span 2;

         a {
            align-self: center;
         }
    }

    .error {
        input, select {
            border: 1.5px solid red;
        }

        .errorMessage {
            color: red;
            font-size: 14px;
            margin-top: 10px;
        }
    }

    .errorSummary {
        grid-column: span 2;
        color: red;
    }
}

@media screen and (max-width: 768px) {
    .faas-form {
        margin: 0px auto;
        max-width: 80%;
        width: 80%;
        min-width: unset;
        padding: 1.5em;
        grid-gap: unset;

        .row {
            grid-column: span 2;
            margin-bottom: 20px;
        }

        input[type=submit] {
            width: 32%;
        }
    }
};
