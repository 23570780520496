@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@import './components/grid/GridTile.scss';
@import './components/grid/Grid.scss';
@import './components/hero-banner/HeroBanner.scss';
@import './components/layout/Layout.scss';
@import './components/navigation/PostLogInNav.scss';
@import './components/navigation/PreLogInNav.scss';
@import './components/screen/Screen.scss';
@import './components/persona-selector/PersonaSelector.scss';
@import './components/tech-drawer/TechDrawer.scss';
@import './components/progress-animation/ProgressAnimation.scss';

@import './pages/chloe/08.scss';
@import './pages/chloe/15.scss';
@import './pages/chloe/16.scss';

.journey-link {
    display: contents;
    cursor: pointer;
}

.pulse-animation {
    box-shadow: 0 0 0 0 #1473E6;
    animation: journey 2s linear -4s infinite;

    &.toast {
        animation: journey 2s linear -4s infinite;
    }
}

@keyframes journey {
    0% {
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.5);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(52, 172, 224, 0.7);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.5);
    }

}
// FOR TESTING/MOCKUP PURPOSES ONLY

.test-list-container {
    display: flex;
    padding: 30px;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    .first-list {
        width: 65%;
    }

    .second-list {
        width: 30%;
    }
}

.test-login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    .test-login-form {
        width: 60%;
    }
}

// END OF TESTING/MOCKUP STYLING