.spinner-overlay {
    height: calc(100vh - 150px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;

    @media screen and (max-width: 768px) {
        height: calc(100vh - 115px)
    }
}

.spinner-container {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(195, 195, 195, 0.6);
    border-radius: 50%;
    border-top-color: #636767;
    animation: spin 1s ease-in-out infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg)
        }
    }
}